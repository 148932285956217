import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";

Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  state: {
    user: {
      loggedIn:
        Vue.$cookies.get("loggedIn") != undefined
          ? Vue.$cookies.get("loggedIn")
          : false,
      data:
        Vue.$cookies.get("userData") != undefined
          ? Vue.$cookies.get("userData")
          : [],
      uid: null,
      token: null,
    },
    searchEmployeeHistory:
      Vue.$cookies.get("searchEmployeeHistory") != undefined
        ? Vue.$cookies.get("searchEmployeeHistory")
        : [],
    searchSaleForceHistory:
      Vue.$cookies.get("searchSaleForceHistory") != undefined
        ? Vue.$cookies.get("searchSaleForceHistory")
        : [],
    searchAnatomyPhysiologyHistory:
      Vue.$cookies.get("searchAnatomyPhysiologyHistory") != undefined
        ? Vue.$cookies.get("searchAnatomyPhysiologyHistory")
        : [],
    searchPathologyHistory:
      Vue.$cookies.get("searchPathologyHistory") != undefined
        ? Vue.$cookies.get("searchPathologyHistory")
        : [],
    searchSpecialtyHistory:
      Vue.$cookies.get("searchSpecialtyHistory") != undefined
        ? Vue.$cookies.get("searchSpecialtyHistory")
        : [],
    searchLaboratoryHistory:
      Vue.$cookies.get("searchLaboratoryHistory") != undefined
        ? Vue.$cookies.get("searchLaboratoryHistory")
        : [],
    searchLineHistory:
      Vue.$cookies.get("searchLineHistory") != undefined
        ? Vue.$cookies.get("searchLineHistory")
        : [],
    searchClassificationHistory:
      Vue.$cookies.get("searchClassificationHistory") != undefined
        ? Vue.$cookies.get("searchClassificationHistory")
        : [],
    searchProductHistory:
      Vue.$cookies.get("searchProductHistory") != undefined
        ? Vue.$cookies.get("searchProductHistory")
        : [],
    searchNotificationHistory:
      Vue.$cookies.get("searchNotificationHistory") != undefined
        ? Vue.$cookies.get("searchNotificationHistory")
        : [],
    duplicateNotification:
      Vue.$cookies.get("duplicateNotification") != undefined
        ? Vue.$cookies.get("duplicateNotification")
        : [],
        duplicateProduct:
        Vue.$cookies.get("duplicateProduct") != undefined
          ? Vue.$cookies.get("duplicateProduct")
          : [],
  },
  mutations: {
    SET_USER_DATA(state, payload) {
      state.user.data = payload;
      Vue.$cookies.set("userData", state.user.data);
    },
    SET_USER_LOOGEDIN(state, payload) {
      state.user.loggedIn = payload;
      Vue.$cookies.set("loggedIn", state.user.loggedIn);
    },
    SET_SEARCH_EMPLOYEE_HISTORY(state, payload) {
      state.searchEmployeeHistory = [];
      state.searchEmployeeHistory.push(payload);
      Vue.$cookies.set("searchEmployeeHistory", state.searchEmployeeHistory);
    },
    SET_SEARCH_SALE_FORCE_HISTORY(state, payload) {
      state.searchSaleForceHistory = [];
      state.searchSaleForceHistory.push(payload);
      Vue.$cookies.set("searchSaleForceHistory", state.searchSaleForceHistory);
    },
    SET_SEARCH_ANATOMY_PHYSIOLOGY_HISTORY(state, payload) {
      state.searchAnatomyPhysiologyHistory = [];
      state.searchAnatomyPhysiologyHistory.push(payload);
      Vue.$cookies.set(
        "searchAnatomyPhysiologyHistory",
        state.searchAnatomyPhysiologyHistory
      );
    },
    SET_SEARCH_PATHOLOGY_HISTORY(state, payload) {
      state.searchPathologyHistory = [];
      state.searchPathologyHistory.push(payload);
      Vue.$cookies.set("searchPathologyHistory", state.searchPathologyHistory);
    },
    SET_SEARCH_SPECIALTY_HISTORY(state, payload) {
      state.searchSpecialtyHistory = [];
      state.searchSpecialtyHistory.push(payload);
      Vue.$cookies.set("searchSpecialtyHistory", state.searchSpecialtyHistory);
    },
    SET_SEARCH_LABORATORY_HISTORY(state, payload) {
      state.searchLaboratoryHistory = [];
      state.searchLaboratoryHistory.push(payload);
      Vue.$cookies.set(
        "searchLaboratoryHistory",
        state.searchLaboratoryHistory
      );
    },
    SET_SEARCH_LINE_HISTORY(state, payload) {
      state.searchLineHistory = [];
      state.searchLineHistory.push(payload);
      Vue.$cookies.set("searchLineHistory", state.searchLineHistory);
    },
    SET_SEARCH_CLASSIFICATION_HISTORY(state, payload) {
      state.searchClassificationHistory = [];
      state.searchClassificationHistory.push(payload);
      Vue.$cookies.set(
        "searchClassificationHistory",
        state.searchClassificationHistory
      );
    },
    SET_SEARCH_PRODUCT_HISTORY(state, payload) {
      state.searchProductHistory = [];
      state.searchProductHistory.push(payload);
      Vue.$cookies.set("searchProductHistory", state.searchProductHistory);
    },
    SET_SEARCH_NOTIFICATION_HISTORY(state, payload) {
      state.searchNotificationHistory = [];
      state.searchNotificationHistory.push(payload);
      Vue.$cookies.set(
        "searchNotificationHistory",
        state.searchNotificationHistory
      );
    },
    SET_DUPLICATE_NOTIFICATION(state, payload) {
      state.duplicateNotification = [];
      state.duplicateNotification.push(payload);
      Vue.$cookies.set(
        "duplicateNotification",
        state.duplicateNotification
      );
    },
    SET_DUPLICATE_PRODUCT(state, payload) {
      state.duplicateProduct = [];
      state.duplicateProduct.push(payload);
      Vue.$cookies.set(
        "duplicateProduct",
        state.duplicateProduct
      );
    },
  },
  actions: {
    setUserData(context, payload) {
      context.commit("SET_USER_DATA", payload);
    },
    setUserLoggedIn(context, payload) {
      context.commit("SET_USER_LOOGEDIN", payload);
    },
    setSearchEmployeeHistory(context, payload) {
      context.commit("SET_SEARCH_EMPLOYEE_HISTORY", payload);
    },
    setSearchSaleForceHistory(context, payload) {
      context.commit("SET_SEARCH_SALE_FORCE_HISTORY", payload);
    },
    setSearchAnatomyPhysiologyHistory(context, payload) {
      context.commit("SET_SEARCH_ANATOMY_PHYSIOLOGY_HISTORY", payload);
    },
    setSearchPathologyHistory(context, payload) {
      context.commit("SET_SEARCH_PATHOLOGY_HISTORY", payload);
    },
    setSearchSpecialtyHistory(context, payload) {
      context.commit("SET_SEARCH_SPECIALTY_HISTORY", payload);
    },
    setSearchLaboratoryHistory(context, payload) {
      context.commit("SET_SEARCH_LABORATORY_HISTORY", payload);
    },
    setSearchLineHistory(context, payload) {
      context.commit("SET_SEARCH_LINE_HISTORY", payload);
    },
    setSearchClassificationHistory(context, payload) {
      context.commit("SET_SEARCH_CLASSIFICATION_HISTORY", payload);
    },
    setSearchProductHistory(context, payload) {
      context.commit("SET_SEARCH_PRODUCT_HISTORY", payload);
    },
    setSearchNotificationHistory(context, payload) {
      context.commit("SET_SEARCH_NOTIFICATION_HISTORY", payload);
    },
    setDuplicateNotification(context, payload) {
      context.commit("SET_DUPLICATE_NOTIFICATION", payload);
    },
    setDuplicateProduct(context, payload) {
      context.commit("SET_DUPLICATE_PRODUCT", payload);
    },
  },
  modules: {},
});
