<template>
  <div class="mb-6">
    <p class="caption mb-1">{{ label }}</p>
    <Editor
      :disabled="disabled"
      v-model="data"
      @onchange="$emit('update', data)"
      :init="{
        promotion: false,
        language: 'pt_BR',
        height: 400,
        menubar: true,
        plugins: editorPlugins,
        selector: '#editor',
        menu: editorMenu,
        menubar: editorMenuBar,
        toolbar: editorToolBar,
      }"
    />
    <p v-if="required && !value" class="red--text mt-1 ml-3 caption">
      Esse campo é obrigatório
    </p>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "EditorTexto",
  components: {
    Editor,
  },
  props: {
    label: {
      type: String,
      default: "Label",
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: null,
    editorPlugins: [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "print",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "paste",
      "code",
      "help",
      "wordcount",
    ],
    // editorApiKey: "7j4oenu13idzv6yk4iqybx3ott9pz4fe50r2jcpzh9gx1tbf",
    // editorApiKey: "hzolje4gzcyjqyimuyjiz2k4i8zut5adl56d2y8b1jdc48sx",
    editorMenu: {
      custom: {
        title: "FQM",
        items: "crm nome especialidade data assinatura",
      },
    },
    editorMenuBar: "file edit view insert format tools table custom",
    editorToolBar:
      "undo redo | formatselect | bold italic backcolor | \
                             alignleft aligncenter alignright alignjustify | \
                             bullist numlist outdent indent | removeformat | help",
  }),
  created() {
    this.data = this.value;
  },
};
</script>

<style></style>
