var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-6"},[_c('p',{staticClass:"caption mb-1"},[_vm._v(_vm._s(_vm.label))]),_c('Editor',{attrs:{"disabled":_vm.disabled,"init":{
      promotion: false,
      language: 'pt_BR',
      height: 400,
      menubar: true,
      plugins: _vm.editorPlugins,
      selector: '#editor',
      menu: _vm.editorMenu,
      menubar: _vm.editorMenuBar,
      toolbar: _vm.editorToolBar,
    }},on:{"onchange":function($event){return _vm.$emit('update', _vm.data)}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),(_vm.required && !_vm.value)?_c('p',{staticClass:"red--text mt-1 ml-3 caption"},[_vm._v(" Esse campo é obrigatório ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }