<template>
  <v-dialog v-model="dialog" width="480" persistent>
    <v-card>
      <v-card-title class="d-flex justify-center flex-column">
        <v-progress-circular
          indeterminate
          color="green"
          class="mb-4"
          :size="48"
        ></v-progress-circular>
        <h2 class="mb-4">{{ dialogTitle }}</h2>
        <span class="mb-2 text-center">{{ dialogMessage }}</span>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LoadingBackup",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default:
        "",
    },
    dialogMessage: {
      type: String,
      default:
        "",
    },
  },
};
</script>
