import Vue from 'vue';
import AlertSuccess from "@/components/custom/AlertSuccess";
import AlertError from "@/components/custom/AlertError";
import AlertConfirmation from "@/components/custom/AlertConfirmation";
import EditorTexto from "@/components/custom/Editor";
import TableModal from "@/components/custom/TableModal";
import LoadingBackup from "@/components/custom/LoadingBackup";

export default () => {
    // register globally
    Vue.component('AlertSuccess', AlertSuccess);
    Vue.component('AlertError', AlertError);
    Vue.component('AlertConfirmation', AlertConfirmation);
    Vue.component('v-editor', EditorTexto);
    Vue.component('TableModal', TableModal);
    Vue.component('LoadingBackup', LoadingBackup);
};